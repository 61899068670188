import React, {useState} from "react";
import styles from './MountainBottom.module.css';
import bg from './../../img/bg2.jpg';
import ellipse from './../../img/ellipse.svg';
import {Element} from 'react-scroll'
import dropdown from './../../img/arrow_down.svg';

const MountainBottom = props => {
    const [textIndex, setTextIndex] = [props.textIndex, props.setTextIndex];
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
    const text = [
        <div>
            <h2>ACCOUNTING</h2>
            <p>We provide full service outsourced accounting for business and investment.</p>
            <p>The standard service includes statutory bookkeeping, VAT and income tax compliance, annual financial statements.</p>
            <p>The service can be supplemented to include, for example </p>
            <ul style={{listStyleImage: "url(" + ellipse + ")"}}>
                <li>payment processing, tax representation, VAT recovery, audit support, assistance with tax audits</li>
                <li>bespoke management reporting, consolidated accounts, cost centre analysis, budgeting, reporting to domestic & international standards</li>
                <li>transaction support, including financial, tax and legal due diligence</li>
                <li>reporting to the Central Statistical Office and National Bank of Poland</li>
                <li>corporate secretarial support</li>
                <li>special projects such as take-over of clients’ in-house accounting teams or transfer pricing projects</li>
            </ul>
            <p>Our accountants operate in teams, each team having specialist experience in supporting clients in different industry sectors.</p>
            <p>Team leaders and their teams are supported by our in-house registered auditors, tax advisers and lawyers. All staff work in Polish and English. We have foreign qualified accountants and lawyers able to support market entrants from overseas. </p>
        </div>,
        <div>
            <h2>Payroll & Hr</h2>
            <p>We deliver a full payroll management service delivered with the highest levels of quality and confidentiality as part of or independent of our accounting service. The service can include HR record keeping and other compliance.</p>
            <p>Our specialist Payroll and HR teams deliver confidential, secure payroll and HR management support.</p>
            <p>Core services include the following</p>
            <ul style={{listStyleImage: "url(" + ellipse + ")"}}>
                <li>registration with the tax and social security authorities</li>
                <li>processing payroll calculations and deductions</li>
                <li>preparation and filing of social security and other declarations</li>
                <li>reporting</li>
                <li>individual payslips</li>
                <li>HR record keeping, employment documentation</li>
                <li>receipt of funds and payment of salaries, tax and social insurance</li>
                <li>dealing with commissions and bonuses, benefits packages</li>
                <li>foreign employer arrangements</li>
                <li>acting as employer of record</li>
            </ul>
        </div>,
        <div>
            <h2>Corporate Services</h2>
            <p>We provide a full range of corporate services including company registry filings and compliance, registered office address and post forwarding, company secretarial compliance and secure document storage. We provide, where appropriate, nominee directors and other nominee appointments for major organisations and investors, particularly in real estate investment.</p>
            <p>We specialise in liquidation of companies and can, in certain cases, take over ownership of unwanted companies, delivering an immediate and trouble free exit. We can help with almost any matter relating to maintaining and operating a Polish corporate entity.</p>
        </div>,
        <div>
            <h2>Registered Office</h2>
            <p>Every Polish business entity is required to have a registered address to be entered in the company register, contracts, tax filings and the like and for receipt of formal correspondence.   We provide a central Warsaw address with dedicated staff and automated electronic post forwarding plus post holding or forwarding.</p>
            <p>Our registered office facility provides also process agency services useful in international trade and financing arrangements.</p>
            <p>We provide secure document storage.  Often this relates to transaction or real estate documentation for investment SPV’s with no physical office.</p>
        </div>,
        <div>
            <h2>Nominee appointments</h2>
            <p>We provide one or more highly experienced professionals to act as Polish resident nominee director(s).  We may also provide nominee ownership of shares.  </p>
            <p>Professionalism, integrity, discretion, confidentiality, availability and loyalty are fundamental to our nominee activity. We have been continuously engaged for high trust, high value appointments for almost three decades. When acting as nominees we act strictly in accordance with agreed parameters, performing actions, reporting, seeking instructions and presenting ourselves as  directed by our client. Everything we do as nominees is bespoke and we maintain the lowest profile possible.</p>
        </div>,
        <div>
            <h2>Corporate Secretarial</h2>
            <p>Subject to the approval of any instructing professionals, we assist with, for example:</p>
            <ul style={{listStyleImage: "url(" + ellipse + ")"}}>
                <li>resolutions and other corporate documentation relating to, for example, changes to board, ownership, shareholdings, company name, share capital, approval of transactions, appointment of auditor, director’s remuneration, banking, other matters</li>
                <li>registrations at company register and as required</li>
                <li>registration of beneficial ownership</li>
                <li>registration of securities</li>
            </ul>
        </div>,
        <div>
            <h2>LIQUIDATION</h2>
            <p>When a company reaches the end of its usefulness, we can assist with the most painless and efficient exit for the investor. </p>
            <p>The usual end of life for a Polish company is liquidation<sup>*</sup>.  We can deal with the entire liquidation and, in some cases, act as liquidator.</p>
            <p>Subject to the approval of instructing professionals, we can be engaged to:</p>
            <ul style={{listStyleImage: "url(" + ellipse + ")"}}>
                <li>accept formal appointment as liquidator</li>
                <li>manage the liquidation process</li>
                <li>supervise asset disposals</li>
                <li>make payments to creditors</li>
                <li>liaise with liquidation accountants</li>
                <li>agree and process third party liquidation costs</li>
                <li>file de-registrations and other formal notifications</li>
                <li>handle preparation of all required court documentation</li>
                <li>handle ongoing and liquidation specific accounting requirements</li>
            </ul>
            <p>We do all or any of this against agreed, fixed fees.</p>
            <p><sup>*</sup> winding-up, bankruptcy, striking-off, deregistration, de-registering, closing down are phrases people often use. Call us to discuss your company’s situation. We can help to close down, wind-up, liquidate or strike-off your company in Poland.</p>
            <p>An alternative in some cases is purchase of shares.</p>
        </div>,
        <div>
            <h2>PURCHASE OF UNWANTED COMPANIES</h2>
            <p>
                When a company reaches the end of its usefulness, we can assist with the most painless and efficient exit for the investor. The usual end of life for a Polish company is liquidation<br/>
                As an alternative to liquidation, the shares in an unwanted company can be transferred to us, delivering an immediate exit from the company for the shareholders and directors.<br/>
                Many investors prefer this solution to liquidation because liquidation proceedings can be lengthy and time consuming.<br/>
                We purchase the unwanted Polish company and then deal with all the work and costs ourselves, not involving the investor or resigning directors.
            </p>
            <p>This provides:</p>
            <ul style={{listStyleImage: "url(" + ellipse + ")"}}>
                <li>immediate exit for the shareholders and directors</li>
                <li>removal of the shares from the shareholder’s balance sheet</li>
                <li>major cost savings</li>
            </ul>
            <p>This eliminates:</p>
            <ul style={{listStyleImage: "url(" + ellipse + ")"}}>
                <li>management involvement in lengthy winding-up proceedings</li>
                <li>continuing costs</li>
                <li>further risks</li>
            </ul>
            <p>We do all of this against agreed, fixed fees.</p>
        </div>,
        <div>
            <h2>Ready Made Companies</h2>
            <p>Blackstones is the leading provider of Polish ready-made companies. Our team developed the market in the 1990’s, with more than 5,000 incorporations to date.</p>
            <p>No other organisation can match Blackstones with regard to its unique combination of experience, expertise, service quality, efficiency and cost.  We are the preferred provider to clients of all leading professional advisers, new market entrants, investment funds, developers, asset managers, banks and others.</p>
            <p>We hold ready to use limited companies (sp. z o.o.), limited partnerships (sp.k) and public companies (SA).  These are fully registered, capitalized and financially and legally compliant, with banking, accounting and a central Warsaw registered office in place.</p>
            <p>We can deliver all other types of entity and multiple entity structures. Our ready-made companies are professionally incorporated, fully compliant and guaranteed. We keep the transfer process as simple as possible.</p>
            <p>We assist in delivering anything required, including:</p>
            <ul style={{listStyleImage: "url(" + ellipse + ")"}}>
                <li>any type of Polish corporate entity</li>
                <li>branches of foreign corporations</li>
                <li>corporate transformations</li>
                <li>mergers of entities</li>
                <li>bespoke corporate structures</li>
            </ul>
            <p>For new market entrants and investors, we deliver a full, turn key solution: a ready-to-use, fully supported trading or investment corporate entity.</p>
        </div>,
       ];
    const activeLineTop = [
        0,40,80,120,160,200,240,280,120
    ];
    const menu = [
        'Accounting',
        'Hr & Payroll',
        'Corporate Services',
        'REGISTERED OFFICE',
        'NOMINEE DIRECTOR',
        'CORPORATE SECRETARIAL',
        'LIQUDATION',
        'PURCHASE OF UNWANTED COMPANIES',
        'Ready Made Companies',
    ];

    return (<div className={styles.MountainBottomWrapper} style={{backgroundImage: "url(" + bg + ")"}} ref={props.forwardedRef}>
        <Element name="services" className={styles.MountainBottom}>
            <div className={styles.SideMenu}>
                <h2>Services</h2>
                <div className={styles.SideMenuWrapper}>
                    <div className={styles.SideMenuLine} style={{top: activeLineTop[textIndex]}}/>
                    {props.isMobile ? <div className={mobileMenuOpened ? [styles.Dropdown, styles.Open].join(' ') : styles.Dropdown} onClick={() => setMobileMenuOpened(!mobileMenuOpened)}>{menu[textIndex]} <img src={dropdown} alt=""/></div> : null }
                    <ul>
                        <li className={textIndex === 0 ? styles.Active : null}><a href="#" onClick={(e) => { e.preventDefault(); setTextIndex(0); setMobileMenuOpened(false); }}>Accounting</a></li>
                        <li className={textIndex === 1 ? styles.Active : null}><a href="#" onClick={(e) => { e.preventDefault(); setTextIndex(1); setMobileMenuOpened(false); }}>Payroll & Hr</a></li>
                        <li className={textIndex === 2 ? styles.Active : null}><a href="#" onClick={(e) => { e.preventDefault(); setTextIndex(2); setMobileMenuOpened(false); }}>Corporate Services</a></li>
                        {props.isMobile || [2,3,4,5,6,7].includes(textIndex) ? <li className={styles.Inactive}>
                            <ul className={styles.Submenu} style={{listStyleImage: "url(" + ellipse + ")"}}>
                                <li className={textIndex === 3 ? styles.Active : null}><a href="#" onClick={(e) => { e.preventDefault(); setTextIndex(3); setMobileMenuOpened(false); }}>REGISTERED OFFICE</a></li>
                                <li className={textIndex === 4 ? styles.Active : null}><a href="#" onClick={(e) => { e.preventDefault(); setTextIndex(4); setMobileMenuOpened(false); }}>NOMINEE appointments</a></li>
                                <li className={textIndex === 5 ? styles.Active : null}><a href="#" onClick={(e) => { e.preventDefault(); setTextIndex(5); setMobileMenuOpened(false); }}>CORPORATE SECRETARIAL</a></li>
                                <li className={textIndex === 6 ? styles.Active : null}><a href="#" onClick={(e) => { e.preventDefault(); setTextIndex(6); setMobileMenuOpened(false); }}>LIQUIDATION</a></li>
                                <li className={textIndex === 7 ? styles.Active : null}><a href="#" onClick={(e) => { e.preventDefault(); setTextIndex(7); setMobileMenuOpened(false); }}>PURCHASE OF UNWANTED COMPANIES</a></li>
                            </ul>
                        </li> : null}
                        <li className={textIndex === 8 ? styles.Active : null}><a href="#" onClick={(e) => { e.preventDefault(); setTextIndex(8); setMobileMenuOpened(false); }}>Ready Made Companies</a></li>
                    </ul>
                </div>
            </div>
            <div className={[styles.Text, [0,1,2,3,4,5,6,7].includes(textIndex) ? styles.AccountingSmall : styles.AccountingFull].join(' ')} >
                {text[textIndex]}
            </div>
        </Element>
    </div>);
}

export default MountainBottom;
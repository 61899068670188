import React, {useEffect, useState} from "react";
import styles from './Kayak.module.css';
import kayak from './../../img/kayak.jpg';
import {Element} from "react-scroll";
import mobile_kayak from './../../img/mobile_kayak.jpg';

const Kayak = props => {
    const [kayakWidth, setKayakWidth] = useState(props.isMobile ? 0 : 816 * window.innerHeight / 1200 + (window.innerWidth > 1440 ? (window.innerWidth - 1440) / 2 + 90 : 90));

    useEffect(() => {
        function handleResize() {
            setKayakWidth(props.isMobile ? 0 : 816 * window.innerHeight / 1200 + (window.innerWidth > 1440 ? (window.innerWidth - 1440) / 2 + 90 : 90));
        }
        window.addEventListener('resize', handleResize)
    }, [props.isMobile]);

    return (<div ref={props.forwardedRef}>
        <Element name="careers" className={styles.Kayak}>
            <div className={styles.KayakImg}>
                <img src={props.isMobile ? mobile_kayak : kayak} alt=""/>
            </div>
            <div style={{width: "calc(100% - " + kayakWidth + "px)"}} className={styles.Content}>
                <h2>Careers</h2>
                <p>We are always looking to add talented and enthusiastic individuals to our team at all levels. Our success depends on attracting the best people and nurturing their talent. We have a continuous recruitment programme. If you are a qualified accountant, junior accountant, a specialist company lawyer or someone looking to qualify into any of these positions, give us a call.</p>
                <p>We recruit on merit and believe in equality of opportunity. There is no barrier to joining our firm or any perfect model candidate, although we do look for people who share our values: our commitment to cherish the trust of our clients and business partners, to deliver the highest quality service and to work within a team of happy and enthusiastic people who respect and trust one another.</p>
                <p>We welcome written applications from anyone interested in joining us. Financial, accounting, legal, administrative, language and office skills and experience will help but we will consider an application from any candidate, even with limited or no work experience, for trainee and support roles. We look for people with career ambition and actively encourage rapid career advancement for everyone. We have a strong and happy team.</p>
                <p>If you join us, you will find that we are committed to helping your career development, whatever your role in our firm. You will learn and develop personal and professional skills as you work alongside colleagues who are leaders in their field. At every stage of your career, we will work with you to help you achieve your career ambitions.</p>
                <p>Your application should be submitted by email to <a href="mailto:recruitment@blackstones.eu">recruitment@blackstones.eu</a> and should include a CV in Polish and English, together with an indication of the type of role you are seeking. Please confirm in your application your consent to processing of your personal data. While we cannot promise to make contact with every applicant, we will review thoroughly every written application we receive.</p>
            </div>
        </Element>
    </div>);
}

export default Kayak;
import React, {useRef, useState} from "react";
import styles from './Menu.module.css';
import logo from './../../img/logo.svg';
import black_logo from './../../img/black_logo.svg';
import open_menu from './../../img/open_menu.svg';
import close_logo from './../../img/close_menu.svg';
import Contact from "../Contact/Contact";
import { Link } from 'react-scroll';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const Menu = props => {
    const [closedMenuClasses, setClosedMenuClasses] = useState([styles.MenuClosed]);
    const [openedMenuClasses, setOpenedMenuClasses] = useState([styles.MenuOpened]);
    const [showServicesMenu, setShowServicesMenu] = useState(false);
    useScrollPosition(({ prevPos, currPos }) => {
        currPos.y < -30 ? setClosedMenuClasses([styles.MenuClosed, styles.NotTop]): setClosedMenuClasses([styles.MenuClosed]);
        currPos.y < -30 ? setOpenedMenuClasses([styles.MenuOpened, styles.NotTop]): setOpenedMenuClasses([styles.MenuOpened]);
    });
    const menuRef = useRef(null);

    const showSubMenu = () => {
        setShowServicesMenu(true);
    }
    const hideSubMenu = () => {
        setShowServicesMenu(false);
    }
    const onMenuLinkClick = () => {
        props.setOpened(false);
        props.setUseAutoScroll(false);
        setTimeout(() => props.setUseAutoScroll(true),500);
    }

    const onNotMenuClick = () => {
        props.setUseAutoScroll(true);
        props.setOpened(false);
    }

    document.body.onscroll = () => props.setOpened(false);
    document.body.onclick = e => menuRef.current ? menuRef.current.contains(e.target) ? null : onNotMenuClick() : null;

    const menu = props.opened ? (<div className={styles.MenuOpenedWrapper}>
        <div className={openedMenuClasses.join(' ')} ref={menuRef}>
            <div>
                <Link
                    activeClass={styles.Active}
                    duration={500}
                    smooth={true}
                    spy={true}
                    to="top"
                    offset={0}
                    onClick={() => { props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500);}}
                    isDynamic={true}
                >
                    <img src={black_logo} className={styles.BlackLogo} alt="Logo Blackstones"/>
                </Link>
                <a href="/pl" className={styles.Button}>PL</a>
                <button type="button">EN</button>
                <img src={close_logo} className={styles.CloseMenu} onClick={() => {props.setOpened(false); props.setUseAutoScroll(true); }} alt="Zamknij menu"/>
            </div>
            <Contact/>
            <div className={styles.Navigation}>
                <ul>
                    <li>
                        <Link
                            activeClass={styles.Active}
                            duration={500}
                            smooth={true}
                            spy={true}
                            to="top"
                            onClick={onMenuLinkClick}
                            offset={0}
                            isDynamic={true}
                        >
                            Home
                        </Link>
                        </li>
                    <li onMouseEnter={showSubMenu} onMouseLeave={hideSubMenu}>
                        <Link
                            activeClass={styles.Active}
                            duration={500}
                            smooth={true}
                            spy={true}
                            to="services"
                            onClick={() => {props.setTextIndex(0); onMenuLinkClick();}}
                            offset={props.isMobile ? -80 : 0}
                            isDynamic={true}
                        >
                            Services
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass={styles.Active}
                            duration={500}
                            smooth={true}
                            spy={true}
                            to="about"
                            onClick={onMenuLinkClick}
                            offset={props.isMobile ? -80 : 0}
                            isDynamic={true}
                        >
                            About us
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass={styles.Active}
                            duration={500}
                            smooth={true}
                            spy={true}
                            to="careers"
                            onClick={onMenuLinkClick}
                            offset={props.isMobile ? -80 : 0}
                            isDynamic={true}
                        >
                            Careers
                        </Link>
                    </li>
                    {props.isMobile ? <li>
                        <Link
                            activeClass={styles.Active}
                            duration={500}
                            smooth={true}
                            spy={true}
                            to="contact"
                            onClick={onMenuLinkClick}
                            offset={-80}
                            isDynamic={true}
                        >
                            Contact
                        </Link>
                    </li> : null }
                </ul>
                <div className={styles.Services} onMouseEnter={showSubMenu} onMouseLeave={hideSubMenu}>
                    {showServicesMenu ?
                        <ul>
                            <li>
                                <Link
                                    duration={500}
                                    smooth={true}
                                    spy={true}
                                    onClick={() => {props.setTextIndex(0); props.setOpened(false); props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500); }}
                                    to="services"
                                    offset={0}
                                    isDynamic={true}
                                >
                                    Accounting
                                </Link>
                            </li>
                            <li>
                                <Link
                                    duration={500}
                                    smooth={true}
                                    spy={true}
                                    onClick={() => {props.setTextIndex(1); props.setOpened(false); props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500); }}
                                    to="services"
                                    offset={0}
                                    isDynamic={true}
                                >
                                    Payroll & Hr
                                </Link>
                            </li>
                            <li>
                                <Link
                                    duration={500}
                                    smooth={true}
                                    spy={true}
                                    onClick={() => {props.setTextIndex(2); props.setOpened(false); props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500); }}
                                    to="services"
                                    offset={0}
                                    isDynamic={true}
                                >
                                    Corporate Services
                                </Link>
                            </li>
                            <li>
                                <Link
                                    duration={500}
                                    smooth={true}
                                    spy={true}
                                    onClick={() => {props.setTextIndex(8); props.setOpened(false); props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500); }}
                                    to="services"
                                    offset={0}
                                    isDynamic={true}
                                >
                                    Ready Made Companies
                                </Link>
                            </li>
                        </ul>
                    :null}
                </div>
            </div>
        </div>
    </div>) : (
    <div className={closedMenuClasses.join(' ')}>
        <Link
            activeClass={styles.Active}
            duration={500}
            smooth={true}
            spy={true}
            to="top"
            onClick={() => { props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500);}}
            offset={0}
            isDynamic={true}
        >
            <img src={logo} alt="Logo Blackstones" className={styles.LogoCLosedWhite}/>
            <img src={black_logo} alt="Logo Blackstones" className={styles.LogoClosedBlack}/>
        </Link>
        <div onClick={() => { props.setOpened(true); props.setUseAutoScroll(false); }}>
            Menu
            <img src={open_menu} alt="Otwórz menu"/>
        </div>
    </div>
    );

    return (<div className={props.opened ? styles.MenuWrapper : [styles.MenuWrapper, styles.Closed].join(' ')}>
        {menu}
    </div>);
}

export default Menu;
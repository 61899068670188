import React from "react";
import styles from './TopBar.module.css';
import {Element} from "react-scroll";

const TopBar = () => {
    return (<div className={styles.TopBarWrapper}>
        <Element name="top" className={styles.TopBar}>
            <a href="tel:+48223908400">+48 22 390 84 00</a>
            <a href="mailto:office@blackstones.eu">office@blackstones.eu</a>
        </Element>
    </div>);
}

export default TopBar;
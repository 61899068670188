import React from "react";
import styles from './CookiesPopup.module.css';
import ellipse from "../../img/ellipse.svg";
import SimpleBar from "simplebar-react";
import Popup from "reactjs-popup";

const CookiesPopup = props => {
    return (<Popup
        open={props.popupOpened}
        closeOnDocumentClick
        modal
        lockScroll
        onClose={() => props.setPopupOpened(false)}
        contentStyle={{width: "100%", maxWidth: "1120px"}}
    >
        <span className={styles.PopupClose} onClick={() => props.setPopupOpened(false)}>
            &times;
        </span>
        <SimpleBar className={styles.CookiesPopup} autoHide={false} scrollbarMaxSize={32}>
            <h2>{props.text}</h2>
            <p>Blackstones uses cookies that identify the browser of this webside user. They collect and store information when the user visits our website about how she/he uses it through which it is possible to record the of the website, as well as provide the user with a better service and experience when browsing and for analytics. The personal data Blackstones collects through these technologies will also be used to manage the session.</p>
            <p>By the pop-up on bottom side of website the user accepts this cookies privacy policy.</p>
            <p>There are two main types of cookies:</p>
            <ul style={{listStyleImage: "url(" + ellipse + ")"}}>
                <li>persistent cookies: record information about your preferences and are stored in your browser cache or mobile device; and</li>
                <li>third party cookies: placed by someone other than us which may gather data across multiple websites or sessions.</li>
            </ul>
            <p>there are the following cookies: </p>
            <ul style={{listStyleImage: "url(" + ellipse + ")"}}>
                <li>analytical / performance cookies - these cookies collect information on how users use Blackstones website, for example which pages are visited most often, whether the user receives any error messages and how the user arrived at Blackstones website. Information collected by these cookies is used only to improve your use of Blackstones website; these cookies are sometimes placed by third party providers of web traffic and analysis services; Blackstones use Google Analytics. For information on how Google processes and collects your information and how you can opt out, please see the following <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">link</a></li>
                <li>functionality cookies - these cookies are used to remember the user’s choices, for example the country the user visits Blackstones website from, your language and any changes the user made to text size and other parts of the web pages that the user can customise to improve the user’s experience and to make the user’s  visits more tailored and enjoyable</li>
                <li>targeting cookies- these cookies record the user’s visit to Blackstones website, the pages the user have visited and the links you have followed.; Blackstones will use this information to make the website and the information (including marketing information) displayed on it more relevant to users’ interests and to measure the effectiveness of and marketing or information initiatives Blackstones may also share this information with third parties for this purpose. They remember the other websites you visit and this information is shared with third party organizations</li>
            </ul>
            <p>More information about cookies is available in the “Settings” or “Help” section in the menu of the users’ web browser.</p>
        </SimpleBar>
    </Popup>);
}

export default CookiesPopup;
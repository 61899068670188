import React from "react";
import styles from './Footer.module.css';
import Contact from "../Contact/Contact";
import {Element, Link} from "react-scroll";

const Footer = props => {
    return (<Element name="footer" className={styles.FooterWrapper}>
        <div className={styles.Footer} ref={props.forwardedRef}>
            <Element name="contact" className={styles.Element}>
                <Contact isMobile={props.isMobile} />
            </Element>

            <div className={styles.Col}>
                <div>
                    <h3 className={styles.Inline}>
                        <Link
                            duration={500}
                            smooth={true}
                            spy={true}
                            to="services"
                            onClick={() => { props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500);}}
                            offset={0}
                            isDynamic={true}
                        >
                            Services
                        </Link>
                    </h3>
                    <h3 className={styles.Inline}>
                        <Link
                            duration={500}
                            smooth={true}
                            spy={true}
                            to="careers"
                            onClick={() => { props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500);}}
                            offset={0}
                            isDynamic={true}
                        >
                            Careers
                        </Link>
                    </h3>
                    <h3 className={styles.Inline}>
                        <Link
                            duration={500}
                            smooth={true}
                            spy={true}
                            to="about"
                            onClick={() => { props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500);}}
                            offset={0}
                            isDynamic={true}
                        >
                            About us
                        </Link>
                    </h3>
                </div>
                <ul>
                    <li>
                        <Link
                            duration={500}
                            smooth={true}
                            spy={true}
                            onClick={() => { props.setTextIndex(0); props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500); }}
                            to="services"
                            offset={0}
                            isDynamic={true}
                        >
                            Accounting
                        </Link>
                    </li>
                    <li>
                        <Link
                            duration={500}
                            smooth={true}
                            spy={true}
                            onClick={() => { props.setTextIndex(1); props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500); }}
                            to="services"
                            offset={0}
                            isDynamic={true}
                        >
                            Payroll & Hr
                        </Link>
                    </li>
                    <li>
                        <Link
                            duration={500}
                            smooth={true}
                            spy={true}
                            onClick={() => { props.setTextIndex(2); props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500); }}
                            to="services"
                            offset={0}
                            isDynamic={true}
                        >
                            Corporate Services
                        </Link>
                    </li>
                    <li>
                        <Link
                            duration={500}
                            smooth={true}
                            spy={true}
                            onClick={() => { props.setTextIndex(8); props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500); }}
                            to="services"
                            offset={0}
                            isDynamic={true}
                        >
                            Ready Made Companies
                        </Link>
                    </li>
                </ul>
            </div>
            <div className={styles.Languages}>
                <a href="/pl">PL</a>
                <button type="button">EN</button>
            </div>
        </div>
    </Element>);
}

export default Footer;
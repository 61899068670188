import React from "react";
import styles from './AboutUs.module.css';
import bg from './../../img/bg3.jpeg';
import Circle from "../UI/Circle/Circle";
import {Element} from "react-scroll";

const AboutUs = props => {
    return (<div className={styles.AboutWrapper } style={{backgroundImage: "url(" + bg + ")"}} ref={props.forwardedRef}>
        <Element name="about" className={styles.About}>
            <h2>About us</h2>
            <div className={styles.Col}>
                Blackstones is the leading and one of the longest standing operators in its field, able to trace its roots to the early 1990’s, and distinguished by its unique combination of experience, expertise, professionalism, reliability, service quality and cost.  We are the preferred provider to clients of all leading professional advisers, market entrants, investment funds, developers, asset managers and banks.
            </div>
            <div className={styles.Col}>
                We have grown organically, with our founding partners remaining firmly involved in all activities on a daily basis. None of our leading people has less than 25 years’ relevant experience in business and professional services, many of us considerably more.
                <br/>
                We support companies operating nationwide from offices in Warsaw and Katowice.
            </div>

            <div className={styles.CircleLine}>
                <Circle number={140}>professionals in our team</Circle>
                <Circle number={500}>regular<br/>clients</Circle>
                <Circle number={5000}>companies created</Circle>
                <Circle number={100}>nominee appointments</Circle>
            </div>
        </Element>
    </div>);
}

export default AboutUs;
import React from "react";
import styles from './Contact.module.css';
import phone from "./../../img/phone.svg";
import mail from "./../../img/mail.svg";
import contact from './../../img/contact.svg';
import location from './../../img/location.svg';

const Contact = props => {
    return (<div className={styles.Contact}>
        <h3>Head office Warsaw</h3>
        <div className={styles.WarsawAddress}>
            <div>
                <a href="https://www.google.com/maps/place/Blackstones+sp%C3%B3%C5%82ka+z+ograniczon%C4%85+odpowiedzialno%C5%9Bci%C4%85+sp.k./@52.2519247,21.0318596,17z/data=!3m1!4b1!4m5!3m4!1s0x471eccf30d88d59d:0xe708bf17514df2be!8m2!3d52.2519247!4d21.0340483" target="_blank" rel="noopener noreferrer">
                    {props.isMobile ? <img src={location} alt=""/> : null}
                    ul. Floriańska 2<br/>
                    03-707 Warszawa
                </a>
            </div>
        </div>
        <div className={styles.PhoneBox}>
            <div>
                <img src={phone} alt="Telefon"/> <a href="tel:+48223908400">+48 22 390 84 00</a>
            </div>
            <div>
                <img src={mail} alt=""/> <a href="mailto:office@blackstones.eu">office@blackstones.eu</a>
            </div>
        </div>
        {props.isMobile ? <img src={contact} className={styles.ContactImg} alt=""/> : null}
        <div className={styles.Offices}>
            <div className={styles.WarsawOffice}>
                <h3>Warsaw Office</h3>
                <div>ul. Grzybowska 2/29</div>
                <div>00-131 Warszawa</div>
            </div>
            <div className={styles.KatowiceOffice}>
                <h3>Katowice Office</h3>
                <div>ul. Dąbrówki 16</div>
                <div>40-081 Katowice</div>
            </div>
        </div>
    </div>);
}

export default Contact;
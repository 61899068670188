import React from "react";
import styles from './Mountain.module.css';
import bg from './../../img/bg1.jpg';
import logo from './../../img/logo.svg';
import {Element, Link} from "react-scroll";

const Mountain = props => {
    const classNames = props.menuOpened ? [styles.Mountain, styles.MenuOpened] : [styles.Mountain];

    return (<div className={styles.MountainWrapper} style={{backgroundImage: "url(" + bg + ")"}} ref={props.forwardedRef}>
        {props.isMobile ? <Element name="top" className={styles.TopBar}/> : null }
        <div className={classNames.join(' ')}>
            <h1>Poland's leading provider of accounting & corporate services</h1>
            <Link
                duration={500}
                smooth={true}
                spy={true}
                to="top"
                offset={0}
                isDynamic={true}
            >
                <img src={logo} alt="Blackstones logo"/>
            </Link>
        </div>
    </div>);
}

export default Mountain;
import React from "react";
import styles from './BottomBar.module.css';
import b from './../../img/blackB.svg';
import black from './../../img/black_logo.svg';
import {Link} from "react-scroll";

const BottomBar = props => {
    return (<div className={styles.BottomBar}>
        {props.isMobile ?
            <Link
                activeClass={styles.Active}
                duration={500}
                smooth={true}
                spy={true}
                to="top"
                onClick={() => { props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500);}}
                offset={0}
                isDynamic={true}
            >
                <img src={black} alt=""/>
            </Link> : null}
        <div><button onClick={() => {props.setText('Privacy policy'); props.setPopupOpened(true);}}>Privacy Policy Terms & conditions</button></div>
        {props.isMobile ? null :
            <Link
                activeClass={styles.Active}
                duration={500}
                smooth={true}
                spy={true}
                to="top"
                onClick={() => { props.setUseAutoScroll(false); setTimeout(() => props.setUseAutoScroll(true),500);}}
                offset={0}
                isDynamic={true}
            >
                <img src={b} alt="Czarne logo"/>
            </Link>}
        <div>© 2020 Blackstones CS Sp. z o.o.</div>
    </div>);
}

export default BottomBar;
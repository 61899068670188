import React from "react";
import styles from './Circle.module.css';
import {useSpring, animated} from 'react-spring'
import { useInView } from 'react-intersection-observer'

const Circle = props => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const springProps = useSpring({
        from: { number: 0 },
        to: { number: inView ? props.number : 0 },
        delay: 300,
        config: {duration: 1000}
    });

    return (<div className={styles.Wrapper}>
        <div className={styles.CircleWrapper}>
            <div className={styles.Circle} ref={ref}>
                >
                <animated.span>{springProps.number.interpolate(number => Math.floor(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</animated.span>
                {props.text}
            </div>
        </div>
        <p>{props.children}</p>
    </div>);
}

export default Circle;
import React, {useState} from "react";
import styles from './Cookies.module.css';
import 'simplebar/dist/simplebar.min.css';

const Cookies = props => {
    const [cookiesClasses, setCookiesClasses] = useState([styles.Cookies]);

    const closeCookies = () => {
        localStorage.setItem('cookies', 'shown');
        setCookiesClasses([styles.Cookies, styles.Hide]);
    }

    return (<div className={cookiesClasses.join(' ')}>
        By browsing this site, you accept the general terms of use and the use of Cookies. <button type="button" className={styles.Link} onClick={() => {props.setPopupOpened(true); props.setText('Cookies'); }}>Learn more</button>
        <button type="button" onClick={closeCookies}>Accept</button>
    </div>);
}

export default Cookies;
import React, {useState} from 'react';
import styles from './App.module.css';
import TopBar from "./components/TopBar/TopBar";
import Mountain from "./components/Mountain/Mountain";
import MountainBottom from "./components/MountainBottom/MountainBottom";
import AboutUs from "./components/AboutUs/AboutUs";
import Kayak from "./components/Kayak/Kayak";
import Footer from "./components/Footer/Footer";
import BottomBar from "./components/BottomBar/BottomBar";
import Menu from "./components/Menu/Menu";
import {useMediaQuery} from "react-responsive";
import handleViewport from 'react-in-viewport';
import {scroller} from "react-scroll";
import Cookies from "./components/Cookies/Cookies";
import CookiesPopup from "./components/CookiesPopup/CookiesPopup";

function App() {
    const [menuOpened, setMenuOpened] = useState(false);
    const [textIndex, setTextIndex] = useState(0);
    const [useAutoScroll, setUseAutoScroll] = useState(true);
    const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
    const [cookiePopupOpened, setCookiePopupOpened] = useState(false);
    const [cookieText, setCookieText] = useState('Cookies');

    const enterViewportOffset = { rootMargin: isMobile ? '-130px' : '-50px'};
    const AboutViewportBlock = handleViewport(props => <AboutUs {...props}/>, enterViewportOffset);
    const MountainViewportBlock = handleViewport(props => <Mountain menuOpened={menuOpened} isMobile={isMobile} {...props}/>, enterViewportOffset);
    const MountainBottomViewportBlock = handleViewport(props => <MountainBottom textIndex={textIndex} setTextIndex={setTextIndex} isMobile={isMobile} {...props}/>, enterViewportOffset);
    const KayakViewportBlock = handleViewport(props => <Kayak isMobile={isMobile} {...props}/>, enterViewportOffset);
    const FooterViewportBlock = handleViewport(props => <Footer setTextIndex={setTextIndex} isMobile={isMobile} setUseAutoScroll={setUseAutoScroll} {...props} />, enterViewportOffset);

    const scroll = useAutoScroll && !isMobile ? name => {
        window.onscroll = function() {
            window.scrollTo(window.scrollX, window.scrollY);
        };

        setTimeout( () => {
            window.onscroll = () => {};
            scroller.scrollTo(name, {
                duration: 500,
                smooth: true,
                offset: isMobile ? -80 : 0,
            });
        },100);
    } : () => {};


    return (<div className={styles.App}>
        <Menu setTextIndex={setTextIndex} opened={menuOpened} setOpened={setMenuOpened} isMobile={isMobile} setUseAutoScroll={setUseAutoScroll} />
        {localStorage.getItem("cookies") ? null : <Cookies setPopupOpened={setCookiePopupOpened} setText={setCookieText}/> }
        <CookiesPopup popupOpened={cookiePopupOpened} setPopupOpened={setCookiePopupOpened} text={cookieText}/>

        <TopBar/>
        <MountainViewportBlock onEnterViewport={() => {scroll('top'); setTimeout(() => setTextIndex(0), 200);}} />
        <MountainBottomViewportBlock onEnterViewport={() => scroll('services')}  />
        <AboutViewportBlock onEnterViewport={() => {scroll('about'); setTimeout(() => setTextIndex(0), 500);}} />
        <KayakViewportBlock onEnterViewport={() => scroll('careers')} />
        <FooterViewportBlock onEnterViewport={() => scroll('footer')} />
        <BottomBar isMobile={isMobile} setUseAutoScroll={setUseAutoScroll} setPopupOpened={setCookiePopupOpened} setText={setCookieText} />
    </div>
  );
}

export default App;
